import React, { useEffect,useState } from 'react'
import {FcBookmark, FcGraduationCap} from 'react-icons/fc'
import { Link, useLocation } from 'react-router-dom'
import './SousMenuVDAF.css'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
export default function SousMenuVDAF(p) {
  const location=useLocation()
  const [item,setItem]=useState(location.pathname)
  const [close,setClose]=useState(false)
  const [closeBtn,setCloseBtn]=useState(false)
  
  useEffect(()=>{ 
    setClose(p)
  }
  ,[p])

  return (
    <div>
      <button className='libelleQSE text-center ' onClick={()=>setClose(!close)}>
       {item==='/VDAF'?<FcBookmark size={25} />:<FcGraduationCap size={25} />}
       <div onClick={()=>setCloseBtn(!closeBtn)} className='text-[#4F4548] font-bold text-center pl-2 mr-2 flex justify-between w-full'  > 
         <span> {item==='/VDAF'?'Informations génerales':item==='/VDAF/m1'?' Master 1':' Master 2'} </span>  
      <div className='pt-1'> {closeBtn==false?<FaChevronDown size={15} color='black'/>:<FaChevronUp color='black' size={15} />} </div>
        </div>
       </button>
       {close==true&& <ul className='memuVDAF space-y-2'>
        <li className={`cursor-pointer flex flex-row space-x-2 items-center  ${item==='/VDAF'?'text-[#4F4548] font-bold ':'text-gray-500'} `}> <FcBookmark size={25} />
           <Link to='/VDAF' > Informations génerales </Link>
            </li>
        
        <li className={`cursor-pointer flex flex-row space-x-2 items-center  ${item==='/VDAF/m1'?'text-[#4F4548] font-bold':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/VDAF/m1' > Master 1 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 items-center  ${item==='/VDAF/m2'?'text-[#4F4548] font-bold':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/VDAF/m2'> Master 2 </Link>
            </li>
      </ul>}
        <ul id='memuVDAFPC' className='p-3 space-y-2 text-base rounded-2xl font-medium tracking-wider font-sans text-start bg-gray-100 w-[250px] mx-2 mt-10'>
        <li className={`cursor-pointer flex flex-row space-x-2 items-center  ${item==='/VDAF'?'text-[#4F4548] font-bold  decoration-[#4F4548]':'text-gray-500'} `}> <FcBookmark size={25} />
           <Link to='/VDAF' > Informations génerales </Link>
            </li>
        
        <li className={`cursor-pointer flex flex-row space-x-2 items-center  ${item==='/VDAF/m1'?'text-[#4F4548] font-bold  decoration-[#4F4548]':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/VDAF/m1' > Master 1 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 items-center  ${item==='/VDAF/m2'?'text-[#4F4548] font-bold  decoration-[#4F4548]':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/VDAF/m2'> Master 2 </Link>
            </li>
      </ul>
    </div>
  )
}
