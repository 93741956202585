import React, { useEffect, useState } from 'react'
import {FcBookmark, FcGraduationCap} from 'react-icons/fc'
import { Link, useLocation } from 'react-router-dom'
import './SousMenuQSE.css'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
export default function SousMenuGRAH(p) {
  const location=useLocation()
  const [item,setItem]=useState(location.pathname)
  const [close,setClose]=useState(false)
  const [closeBtn,setCloseBtn]=useState(false)
  
  useEffect(()=>{ 
    setClose(p)
  }
  ,[p])
  const onClose=()=>{
    setClose((prev)=>!prev)
    setCloseBtn((prev)=>!prev)
  }
  return (
    <div>
       <button className='libelleQSE' onClick={()=>onClose()}>
       {item==='/GRAH'?<FcBookmark size={25} />:<FcGraduationCap size={25} />}
       <div className='text-gray-500 font-bold text-center pl-2 text-red-500 flex justify-between w-full'  > 
       <span> {item==='/GRAH'?'Informations génerales':item==='/GRAH/l3'?' Licence 3':item==='/GRAH/m1'?' Master 1':' Master 2'} </span>
      <div className='pt-1'> {closeBtn==false?<FaChevronDown size={15} color='black'/>:<FaChevronUp color='black' size={15} />} </div>
      </div>
       </button>
      {close==true&&<ul className='memuQSE space-y-2'>
        <li className= {`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH'?'text-red-500 ':'text-gray-500'} `}> <FcBookmark size={25} />
           <Link to='/GRAH' > Informations génerales </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH/l3'?'text-red-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/GRAH/l3' > Licence 3 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH/m1'?'text-red-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/GRAH/m1' > Master 1 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH/m2'?'text-red-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/GRAH/m2'> Master 2 </Link>
            </li>
      </ul>}
      <ul id='memuQSEPC' className='p-3 space-y-1 text-base rounded-2xl font-medium tracking-wider font-sans text-start bg-gray-100 w-[250px] mx-2 mt-10 '>
      <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH'?'text-red-500 font-bold  decoration-red-500':'text-gray-500'} `}> <FcBookmark size={25} />
           <Link to='/GRAH' > Informations génerales </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH/l3'?'text-red-500 font-bold  decoration-red-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/GRAH/l3' > Licence 3 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH/m1'?'text-red-500 font-bold  decoration-red-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/GRAH/m1' > Master 1 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/GRAH/m2'?'text-red-500 font-bold  decoration-red-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/GRAH/m2'> Master 2 </Link>
            </li>
      </ul>
    </div>
  )
}
