import React, { useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {ImCheckboxChecked,ImCheckboxUnchecked} from 'react-icons/im' 
import {FaCamera, FaChevronDown, FaChevronUp,} from 'react-icons/fa' 
import { useForm } from "react-hook-form";
//import { useDispatch,  } from "react-redux";
//import {preinscritActions}from '../reducer/preinscrit'
import './preinscription.css';
import { BeatLoader } from 'react-spinners';
import  Axios  from 'axios';
import Backdrop from '../component/Backdrop';
import { useNavigate } from 'react-router-dom';
import Header from '../component/header'
const schema = yup
.object({
  nom: yup.string().required("Veuillez renseigner votre nom"),
  prenoms: yup.string().required("Veuillez renseigner votre prenom"),
  email: yup.string("Veuillez renseigner votre email").email("format email incorrect "),
  tel: yup.string("Veuillez renseigner votre numero de télèphone"),
  whatshapp: yup.string("Veuillez renseigner votre numero de whatshapp")
})
.required()

export default function PREINSCRIPTIONCOMP() {
 //const dispatch = useDispatch();
  const { register,watch,getValues, handleSubmit, formState: { errors },} = useForm(
   { resolver: yupResolver(schema),}
  );
  const navigate = useNavigate()
    const [loading,setLoading]=useState(false) 
    const [message,setMessage]=useState(false) 
    const [file,setFile]=useState(null) 
    const [visibilite,setVisibilite]=useState(false)
   
     
    
     
     const onSubmit = async (data) => {
      setLoading(true)

      try {
        
        const formdata = new FormData()
        formdata.append("file",file)
        formdata.append("upload_preset","cfcpdf")
        Axios.post(
         "https://api.cloudinary.com/v1_1/cfcunadoc/image/upload",
         formdata
        ).then( async (response)=>{
          await fetch("https://sheetdb.io/api/v1/0ai70r94pmbue",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
    body: JSON.stringify({
        data: [
          {
            'id': "INCREMENT",
              'nom':data.nom,
              'prenoms':data.prenoms,
              'email':data.email,
              'annee':data.annee,
              'whatshapp':data.whatshapp,
              'niveau':niveau(data.diplome),
              'Releve_note_Diplome':response.data.secure_url,
              'tel':data.tel,
              'filiere':filiere(data.filiere)
            }
        ]
    }) } ).then(()=>{
      setLoading(false)
      setMessage(true)
    })
          
        })
      } catch (error) {
        console.log(error)
      }
     
     
  }

 
  const filiere =(p)=>{
    switch (p) {
      case 'Qualité, Securité et Environnement (QSE)':
       return 'QSE'
      case 'Nutrition et Securités alimentaires (NSA)':
       return 'NSA'
      case 'Statistique et Informatique Décisionnelle (SID)':
       return 'SID'
      case 'Gestion Durable des Ressources Animales et Halieutiques (GRAH)':
       return 'GRAH'
      default:
        return 'QSE'
    }
  }
  const niveau =(p)=>{
    switch (p) {
      case 'Preinscription en Licence 2':
       return 'Licence2'
      case 'Preinscription en Licence 3':
       return 'Licence3'
      case 'Preinscription en Master 1':
       return 'Master1'
      case 'Preinscription en Master 2':
       return 'Master2'
      default:
        return 'Licence3'
    }
  }
  const option=(p)=>{
    switch (p) {
      case "Qualité, Securité et Environnement (QSE)":
        return(
          <>
            <option>Preinscription en Licence 2</option>
            <option>Preinscription en Licence 3</option>
            <option>Preinscription en Master 1</option>
            <option>Preinscription en Master 2</option>
          </>
        )
      case "Statistique et Informatique Décisionnelle (SID)":
        return(
          <>
            <option>Preinscription en Licence 2</option>
            <option>Preinscription en Licence 3</option>
            <option>Preinscription en Master 1</option>
            <option>Preinscription en Master 2</option>
          </>
        )
      case "Valorisation des Dechets Agricoles et Forestiers (VDAF)":
        return(
          <>
            <option>Preinscription en Master 1</option>
            <option>Preinscription en Master 2</option>
          </>
        )
      case "Gestion Durable des Ressources Animales et Halieutiques (GRAH)":
        return(
          <>
             <option>Preinscription en Licence 3</option>
            <option>Preinscription en Master 1</option>
            <option>Preinscription en Master 2</option>
          </>
        )
    
      default:
        return(
          <>
            <option>Preinscription en Licence 2</option>
            <option>Preinscription en Licence 3</option>
            <option>Preinscription en Master 1</option>
            <option>Preinscription en Master 2</option>
          </>
        )
    }
  }
  
    
  return (
    <div className='w-full items-center justify-center'>
      
      {message==true&&<div className='h-full absolute w-full'>
          <Backdrop/>
         <div id="back" className='px-3 py-1 bg-white w-[500px] h-64 rounded-lg absolute z-20  top-56 left-[30%] flex items-center justify-center flex-col space-y-4 '>
           <p className='text-[24px] text-red-500 '>
            Merci future auditeur
           </p>
           <p className='text-[20px] tracking-wider font-medium text-center '>
             Votre Dépot de candidature a étè effectuée avec success
           </p>
             <p className='text-gray-500 text-center'>Nous examinons votre candidature, un email vous sera envoyé pour demarrer votre inscription</p>
              <button className='bg-green-500 w-32 h-12 rounded-lg shadow-md' onClick={()=>navigate("/")}>
                   Retour
              </button>
         </div>
         </div>}
      <Header visibiliteBackdrop={(p)=>setVisibilite(p)}/>
      <form autoComplete='off' className='overflow-y-scroll h-[700px] flex w-full justify-center  items-center flex-col space-y-4 p-9 overflow-y-scroll' onSubmit={handleSubmit(onSubmit)} >
            <p id='titre' className='text-[35px] text-blue-600 font-medium'>PREINSCRIPTION EN LIGNE</p>
            <div className='flex flex-row'>
                <p>Année Academique </p>
                <select {...register("annee")} defaultValue='2024-2025' className='outline-none border-b-2 py-1 text-gray-400 p-2 text-sm'>
                    <option className='py-1' >2024-2025</option>
                    </select>
            </div>
      
        <input id="nom" {...register("nom")} type='text' placeholder='Nom' className='outline-none  border-b-2 py-1 text-lg w-[600px] '/>
        {errors.nom?.message&&<p className='text-red-400 text-sm '>{errors.nom?.message}</p>}
        <input id="prenoms" {...register("prenoms")} type='text' placeholder='Prenoms' className='outline-none  border-b-2 py-1 text-lg w-[600px] '/>
        {errors.prenoms?.message&&<p className='text-red-400 text-sm '>{errors.prenoms?.message}</p>}
        <input id="tel" {...register("tel")} type='text' placeholder='Tel' className='outline-none  border-b-2 py-1 text-lg w-[600px] '/>
        {errors.tel?.message&&<p className='text-red-400 text-sm '>{errors.tel?.message}</p>}
        <input id="email" {...register("email")} type='email' placeholder='Email' className='outline-none  border-b-2 py-1 text-lg w-[600px] '/>
        {errors.email?.message&&<p className='text-red-400 text-sm '>{errors.email?.message}</p>}
        <input id="whatshapp" {...register("whatshapp")} type='Whatshapp' placeholder='Whatshapp' className='outline-none  border-b-2 py-1 text-lg w-[600px] '/>
        {errors.email?.message&&<p className='text-red-400 text-sm '>{errors.email?.message}</p>}
        <select id="filiere"  {...register("filiere")} defaultValue='Qualité, Securité et Environnement (QSE)' className='outline-none  border-b-2 py-1 text-lg w-[600px] '>
            <option>Qualité, Securité et Environnement (QSE)</option>
            <option>Statistique et Informatique Décisionnelle (SID)</option>
            <option>Valorisation des Dechets Agricoles et Forestiers (VDAF)</option>
            <option>Gestion Durable des Ressources Animales et Halieutiques (GRAH)</option>
        </select>
        <select id="diplome" {...register("diplome")} defaultValue='Preinscription en Licence 3' className='outline-none  border-b-2 py-1 text-lg w-[600px] '>
             {option(getValues("filiere")||watch("filiere"))}
        </select>
        <div id='divFile' className='flex flex-col items-center  w-[600px]'>

        <p className='p-1 ml-1 w-full flex items-center justify-between cursor-pointer text-lg  '>
                Dernier diplôme après le BAC ou relevé de notes
            </p>
        <input {...register("autre")} type='file' id="file"  accept="application/pdf" 
           className='block w-full text-sm text-slate-500 mt-2
           p-3 file:mr-4 file:py-2 file:px-4 file:rounded-md 
           file:border-0 file:text-sm file:font-semibold
           file:bg-pink-50 file:text-pink-700
           hover:file:bg-pink-100'
           onChange={(e)=>setFile(e.target.files[0])} 
           />
           </div>
        {file!==null&&<button type='submit' id='buttonSubmit' className='outline-none flex flex-row items-center justify-center space-x-2  text-white bg-green-700 hover:bg-green-800  font-medium rounded-lg text-lg  px-5 py-2.5 mr-2 mb-2'>
          {loading===true && <BeatLoader
        color={"white"}
        size={4}
        aria-label="Loading Spinner"
        data-testid="loader"
      />} <span>Envoyer</span>
        </button>}

      </form>
    </div>
  )
}
