import React from 'react'
import { Link } from 'react-router-dom'
import './SectionApres.css'
export default function SectionApres() {
  return (
    <div className=' flex flex-col items-center justify-center  ' style={{marginTop:'50px'}}>
     <img src={require('../assets/chap.png')} alt='' className='w-[70px] ' />
     <p id='titreForm' className='font-sans font-semibold text-[35px] text-center text-black '>Nos <span className='text-[#9A3BE5] '> formations </span> diplômantes</p>
     
       <div id='sectionUl' className='flex space-x-10 mt-4 ml-8'>
        <ul className='flex flex-col  items-center justify-center bg-white border-t-O border-l-2 border-r-2 border-b-2  border-white shadow-xl '>
            <li className='bg-[#50982F] w-[300px]  text-center font-light p-2 text-[20px] tracking-widest  text-white'>Qualité, Securité et Environnement (QSE) </li>
            <li className='p-2 text-[20px] font-medium  text-green-600 cursor-pointer  '><Link to='/QSE/l2'>Licence 2</Link></li>
            <li className='p-2 text-[20px] font-medium  text-green-600 cursor-pointer  '><Link to='/QSE/l3'>Licence 3</Link></li>
            <li className='p-2 text-[20px] font-medium  text-green-600 cursor-pointer  '><Link to='/QSE/m1'>Master 1</Link></li>
            <li className='p-2 text-[20px] font-medium  text-green-600 cursor-pointer  '><Link to='/QSE/m2'>Master 2</Link></li>
        </ul>
        <ul className='flex flex-col  items-center justify-center bg-white border-t-O border-l-2 border-r-2 border-b-2  border-white shadow-xl '>
            <li className='bg-[#A038F1] w-[300px]  text-center font-light p-2 text-[20px] tracking-widest  text-white'>Statistique et Informatique Décisionnelle (SID) </li>
            <li className='p-2 text-[20px] font-medium  text-violet-600 cursor-pointer  '><Link to='/SID/l2'>Licence 2</Link></li>
            <li className='p-2 text-[20px] font-medium  text-violet-600 cursor-pointer  '><Link to='/SID/l3'>Licence 3</Link></li>
            <li className='p-2 text-[20px] font-medium  text-violet-600 cursor-pointer  '><Link to='/SID/m1'>Master 1</Link></li>
            <li className='p-2 text-[20px] font-medium  text-violet-600 cursor-pointer  '><Link to='/SID/m2'>Master 2</Link></li>
        </ul>
        <ul className='flex flex-col  items-center  justify-start bg-white border-t-O border-l-2 border-r-2 border-b-2  border-white shadow-xl '>
            <li className='bg-[#4F4548] w-[300px]  text-center font-light p-2 text-[20px] tracking-widest  text-white'>Valorisation des Dechets Agricoles et Forestiers (VDAF) </li>
            <li className='p-2 text-[20px] font-medium  text-[#4F4548] cursor-pointer  '><Link to='/VDAF/m1'>Master 1</Link></li>
            <li className='p-2 text-[20px] font-medium  text-[#4F4548] cursor-pointer  '><Link to='/VDAF/m2'>Master 2</Link></li>
        </ul>
        <ul className='flex flex-col  items-center  justify-start bg-white border-t-O border-l-2 border-r-2 border-b-2  border-white shadow-xl '>
            <li className='bg-red-500 w-[300px]  text-center font-light p-2 text-[20px] tracking-widest  text-white'>Gestion durable des Ressources Animales et Halieutiques (GRAH) </li>
            <li className='p-2 text-[20px] font-medium  text-red-500 cursor-pointer  '><Link to='/GRAH/l3'>Licence 3</Link></li>
            <li className='p-2 text-[20px] font-medium  text-red-500 cursor-pointer  '><Link to='/GRAH/m1'>Master 1</Link></li>
            <li className='p-2 text-[20px] font-medium  text-red-500 cursor-pointer  '><Link to='/GRAH/m2'>Master 2</Link></li>
        </ul>
       </div>
     
    
    </div>
  )
}
