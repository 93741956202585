import React, { useState } from 'react'
import Header from '../component/header'
import SousMenuGRAH from '../component/SousMenuGRAH.js'
import SurPage from '../component/SurPage.js'
import { Link } from 'react-router-dom'
import './QSE.css'
import Backdrop from '../component/Backdrop.js'
const rub=[
  {
    nom:'Objectifs de la formation',
    lien:'#of'
  },{ 
  nom:'Modalités de recrutement',
  lien:'#mr'
},
  {
    nom:'Débouchés',
    lien:'#deb'
  },
  {
    nom:'Coût de la formation',
    lien:'#deb'
  },
  
] 
const QSE_SWITCH=({tag})=>{
    switch (tag) {
        case 'pre':
          return(  <p className='text-lg text-gray-600 font-normal m-2 p-2'>
              Le postulant se rend au CFC-UNA pour retirer une fiche de préinscription sur laquelle est la liste des pièces à fournir et à déposer pour l’équivalence avec la somme de 5000 FCFA ou effectuer une  <Link to='/preinscription'  className='text-green-500  cursor-pointer'>Preinscription en ligne </Link> <br/>
              Après analyse de dossiers, les auditeurs dont les dossiers ont été retenus sont contactés par la Direction du CFC-UNA, afin de procéder à leur inscription physique.
            </p>)
        case 'insc':
          return(  <p className='text-lg text-gray-600 font-normal m-2 p-2'>
              Comment se déroule l’inscription au CFC ?
              <br/>
              <dd className='indent-3 space-y-2'>
                    <dt> - Se rendre au CFC pour le retrait du bulletin de versement</dt>
                    <dt>- Se rendre à la caisse de l’UNA pour faire le versement</dt>
                    <dt>- Revenir au bureau avec la photocopie du reçu de paiement</dt>
                    <dt>- Se rendre ensuite à la Scolarité pour finaliser l’inscription munie des pièces ci-dessous :
                        <ul className='ml-5  font-medium text-black'>
                            <li>• Extrait d’acte de naissance ou une fiche individuelle d’état civil (originale)</li>
                            <li>• Photocopie de l’ttestation et relevés de notes du BACCALAUREAT (sur présentation de l’original).</li>
                            <li>• Carte nationale d’identité ou passport + une copie  de la pièce présentée</li>
                            <li>• 3 photos d’identité couleur du même tirage</li>
                            <li>• Le reçu de paiement des frais d’inscripion</li>
                        </ul>
                    </dt>
                </dd>
            </p>)
        case 'cout':
          return( <div className='p-3'> <p className='text-lg text-gray-600 font-normal m-2 p-2'>
              La Licence coûte <span className='text-black'> 880 000 F</span></p>
              <div className='flex justify-center items-center flex-col space-y-10'>
              <table>
              <thead>
                <tr>
                  <th class=" w-72 text-center"></th>
                  <th class="border border-slate-300 w-72 text-center">Montant</th>
               </tr>
                </thead>
                <tbody>
                     <tr>
                     <td class="border border-slate-300 w-72 text-center">Inscripton</td>
                     <td class="border border-slate-300 w-72 text-center font-bold">30 000 F CFA</td>
                      </tr>
                      <tr>
                        <td class="border border-slate-300 w-72 text-center">Droit de scolarité </td>
                        <td class="border border-slate-300 w-72 text-center font-bold">850 000 F CFA</td>
                        </tr>
                </tbody>
              </table>

              </div>
              </div>
            )
            case 'modal':
                return( <div className='p-3'> <p className='text-lg text-gray-600 font-normal m-2 p-2'>
                    le paiement se fait en 4 versements</p>
                    <div className='flex justify-center items-center flex-col space-y-10'>
                    <table>
                    <thead>
                      <tr>
                        <th class=" w-72 text-center"></th>
                        <th class="border border-slate-300 w-72 text-center">Montant</th>
                      
                     </tr>
                      </thead>
                      <tbody>
                          
                            <tr><td class="border border-slate-300 w-72 text-center ">1ere versement</td><td class="border border-slate-300 w-72 text-center font-bold">330 000 F (à payer à l’inscription)</td></tr>
                           <tr><td class="border border-slate-300 w-72 text-center ">2éme versement</td><td class="border border-slate-300 w-72 text-center font-bold">200 000 F</td></tr>
                           <tr><td class="border border-slate-300 w-72 text-center ">3éme versement</td><td class="border border-slate-300 w-72 text-center font-bold">200 000 F</td></tr>
                           <tr><td class="border border-slate-300 w-72 text-center ">4éme versement</td><td class="border border-slate-300 w-72 text-center font-bold">150 000 F</td></tr>
                           
                       
                      </tbody>
                    </table>
                    </div>
                    </div>
                  )
    
        default:
            return(  <p className='text-lg text-gray-600 font-normal m-2 p-2'>
            Le postulant se rend au CFC-UNA pour retirer une fiche de préinscription sur laquelle est la liste des pièces à fournir et à déposer pour l’équivalence avec la somme de 5000 FCFA ou    .
            <br/>
            Après analyse de dossiers, les auditeurs dont les dossiers ont été retenus sont contactés par la Direction du CFC-UNA, afin de procéder à leur inscription physique.
          </p>)
    }
}
export default function QSE() {
     const [tag,setTag]=useState('cout')
     const [visibilite,setVisibilite]=useState(false)
     const [mask,setMask]=useState(false)
     const visibiliteBackdropFun=(p)=>{
      setVisibilite(p)
      setMask(false)
    }
  return (
    <div >
         <div className='absolute top-0 right-0 -z-10'>
     <svg width="329" height="381" viewBox="0 0 329 381" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path d="M172.99 172.752C34.2967 130.304 -7.07007 94.1942 0.953251 2.09143V0H929V581C769.989 572.382 639.56 543.307 606.356 478.938C560.807 390.636 526.261 357.261 441.463 339.23C320.123 328.821 272.374 313.17 234.9 263.102C212.95 216.501 199.723 195.054 172.99 172.752Z" fill="red" fillOpacity="0.2"/>
    </svg>
  </div>
  {visibilite==true&&<Backdrop />}
  <Header visibiliteBackdrop={(p)=>visibiliteBackdropFun(p)}/>
      <div className='qseSaction'>
       <div className='sousMenu'> <SousMenuGRAH p={mask} /></div>
      <div className=' mx-2 overflow-y-scroll h-[700px] '>
      <ul className=''>
      <li id='of'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Objectifs de la formation</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                La formation en Licence 3 Gestion Durable des Ressources Animales et Halieutiques (L3GRAH) dure deux semestres (12 mois). Elle fait une mise à niveau des apprenants en Biologie Animale et en Bio-Statistique qui sont des outils importants d’analyse et de prise de décision pour le Responsable d’une entreprise de Productions Animales. La communication étant également un élément important pour le développement d’une entreprise, une innovation a été apportée. Ainsi, en plus de l’Anglais, le Mandarin (Langue Nationale Chinoise) a été introduite dans cette formation. La formation proposée est une formation pratique et professionnalisant. C’est pour quoi, toutes les unités d’Enseignements sont accompagnées de travaux pratiques et d’activités extra-muros. Le parcours se termine par un Stage en entreprise et par un projet de fin d’étude (PFE).
                </p>
                </div>
                <li id='mr'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Modalités de recrutement</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Peuvent faire acte de candidature à la Licence 3 Gestion Durable des Ressources Animales et Halieutiques :
                <dt>- tout apprenant ayant validé les 4 semestres de la licence 1 et la licence 2 acquisition de la totalité des 120 crédits de L1, L2 de Sciences de la Nature (SN) ; </dt>
                <dt>- par dérogation, tout étudiant non doublant ayant validé les 80 % des Unités d’enseignement de la licence 2 de SN ; </dt>
                <dt>- tout apprenant justifiant d’un diplôme admis en équivalence de la Licence (L1 et L2) par les autorités compétentes </dt>
                <dt>- tout apprenant en dispense de Validation des Acquis d’Expérience (VAE) ou Validation des Acquis Professionnels (VAP) et compatible à la formation Gestion Durable des Ressources Animales et Halieutiques selon la CFC. </dt>
                </p>
                </div>

           <li id='deb'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Débouchés</li>
               <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                L'insertion professionnelle est très aisée pour les personnes titulaires de la Licence Professionnelle GRAH. Les different emplois sont :
                
                <dd className='indent-3 space-y-2'>
                    <dt>- Auto-emplois : Eleveur (aulacode, volaille, porc, hérisson, achatine, …), Provendier, Charcutier …</dt>
                    <dt>- Technicien avancé : Assistant zootechnicien, Technicien de laboratoire, Technicien d’élevage, Fabricant d’aliment bétail, Gestionnaire des intrants, Gestionnaire de cultures fourragères… </dt>
                    <dt>- Service vétérinaire :Agent de détection des maladies (diagnostique), assistant vétérinaire, commercialisation de produits vétérinaires</dt>
                    <dt>- Alimentation-nutrition : Gestionnaire des stocks d’aliments, Gestionnaire de l’alimentation, Assistant nutritionniste animale </dt>
                    <dt>- Emplois par voies de concours BAC+3 : Concours, MIRAH, MINAGRI, EAUX et FORETS, ENS, INFAS et autres</dt>
                </dd></p>
                </div>
        </ul>
         <ul className='flex w-[90%] justify-between items-center mx-6 '>
            <li className={`text-lg  ${tag==='pre'&&'border-b-2 border-red-400  text-black font-bold'}  cursor-pointer `} onClick={()=>setTag('pre')} >Pre-inscription</li>
            <li className={`text-lg  ${tag==='insc'&&'border-b-2 border-red-400  text-black font-bold'}  cursor-pointer `} onClick={()=>setTag('insc')} >Inscription</li>
            <li className={`text-lg  ${tag==='cout'&&'border-b-2 border-red-400  text-black font-bold'}  cursor-pointer `} onClick={()=>setTag('cout')} >Coût</li>
            <li className={`text-lg  ${tag==='modal'&&'border-b-2 border-red-400  text-black font-bold'}  cursor-pointer `} onClick={()=>setTag('modal')} >Modalité</li>
         </ul>
           <div id='pre'> <QSE_SWITCH tag={tag} /></div>
      </div>
      <div className='surPage'>
      <SurPage colorTg={'text-purple-500'} rub={rub} />
      </div> </div>
    </div>
  )
}
