import React, { useEffect, useState } from 'react'
import {FcBookmark, FcGraduationCap} from 'react-icons/fc'
import { Link, useLocation } from 'react-router-dom'
import './SousMenuQSE.css'
import { FaChevronDown,FaChevronUp } from 'react-icons/fa'
export default function SousMenuQSE(p) {
  const location=useLocation()
  const [item,setItem]=useState(location.pathname)
  const [close,setClose]=useState(false)
  const [closeBtn,setCloseBtn]=useState(false)
  
  useEffect(()=>{ 
    setClose(p)
  }
  ,[p])
  return (
    <div>
       <button className='libelleQSE text-center ' onClick={()=>setClose(!close)}>
       {item==='/QSE'?<FcBookmark size={25} />:<FcGraduationCap size={25} />}
       <div onClick={()=>setCloseBtn((prev)=>!prev)} className='text-green-500 font-bold text-center pl-2 mr-2 flex justify-between w-full'  > 
         <span> {item==='/QSE'?'Informations génerales':item==='/QSE/l2'?' Licence 2':item==='/QSE/l3'?' Licence 3':item==='/QSE/m1'?' Master 1':' Master 2'} </span>  
      <div className='pt-1'> {!closeBtn?(<FaChevronDown size={15} color='black'/>):(<FaChevronUp color='black' size={15} />)} </div>
        </div>
       </button>
      {close==true&&<ul className='memuQSE space-y-2'>
        <li className= {`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE'?'text-green-500 ':'text-gray-500'} `}> <FcBookmark size={25} />
           <Link to='/QSE' > Informations génerales </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/l2'?'text-green-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/l2' > Licence 2 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/l3'?'text-green-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/l3' > Licence 3 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/m1'?'text-green-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/m1' > Master 1 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/m2'?'text-green-500 ':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/m2'> Master 2 </Link>
            </li>
      </ul>}
      <ul id='memuQSEPC' className='p-3 space-y-1 text-base rounded-2xl font-medium tracking-wider font-sans text-start bg-gray-100 w-[250px] mx-2 mt-10 '>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE'?'text-green-500 font-bold  decoration-green-500':'text-gray-500'} `}> <FcBookmark size={25} />
           <Link to='/QSE' > Informations génerales </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/l2'?'text-green-500 font-bold  decoration-green-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/l2' > Licence 2 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/l3'?'text-green-500 font-bold  decoration-green-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/l3' > Licence 3 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/m1'?'text-green-500 font-bold  decoration-green-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/m1' > Master 1 </Link>
            </li>
        <li className={`cursor-pointer flex flex-row space-x-2 text-gray-500 items-center  ${item==='/QSE/m2'?'text-green-500 font-bold  decoration-green-500':'text-gray-500'} `}> <FcGraduationCap size={25} />
           <Link to='/QSE/m2'> Master 2 </Link>
            </li>
      </ul>
    </div>
  )
}
