import React from 'react'
import { FcClock } from 'react-icons/fc'

export default function INFORMATION_GRAH() {
  return (
    <div className='overflow-y-scroll  h-[700px]'>
        <ul >
            <li id='bf'  className='font-medium text-2xl tracking-wider py-3 px-1'>Besoins de formation</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Licence et Master <span className='text-red-500 font-normal'>Gestion Durable des Ressources Animales et Halieutiques</span> sont des formations professionnelles organisées dans une université publique de Côte d’Ivoire. Les formations sont pilotées par une équipe pédagogique très expérimentée (au moins 10 ans d’expérience) dirigée par le Dr Moussa KIMSE, Maitre de Conférences. Le parcours de formation vise à offrir une opportunité aux personnes titulaires d’un BTS des grandes écoles ou d’un diplôme équivalent, la Licences ou d’un diplôme équivalent et des professionnelles expérimentés marginalisés ou exclus de la formation classique proposée par les universités et grandes écoles publiques de Côte d’Ivoire
                </p>
            </div>
            {/*<li id='cac'  className='font-medium text-2xl tracking-wider py-3 px-1'>Connaissances à acquérir </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                GRAH forme des personnes à compétence avancée dans les techniques de gestion et de conduite des élevages. Elles sont capables de produire des projets d’élevage, gérer des pâturages et de formuler des aliments complets. Les personnes formées feront des autopsies. Elles sont capables de prendre une décision rationnelle à l’aide de la mise en place de tests sommaires. Ce sont des soutiens pour le vétérinaire et l’ingénieur dans leurs tâches respectives. Le diplômé aura à sa disposition un projet de création d’une entreprise à la fin de la formation. Il est prêt à l’auto-emploi et toutes les taches techniques et administratives liées à sa formation.
                </p>
            </div> */}
           
           {/* <li id='cv'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Compétences visées</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Les Assistants techniques disposeront d'une formation leur permettant de contribuer au développement de leur entreprise en mettant en œuvre des démarches en Qualité, Sécurité et en Environnement (Système de Management Intégré). Ces Assistants techniques seront capables de : 
                <dd className='indent-3 space-y-2 mb-2'>
                    <dt> - S'approprier la démarche “QSE” : Qualité, Sécurité, Environnement de l'entreprise en utilisant les outils de la Qualité, de la Sécurité et de l'Environnement (méthodes de résolution de problèmes, statistiques appliquées, hiérarchisation des risques,...) ;</dt>
                    <dt>- Maîtriser les normes et règlements, ainsi que l'écriture des divers documents correspondants</dt>
                    <dt>- Participer à la mise en œuvre de la politique “QSE” de l'entreprise, en maîtrisant les coûts en Qualité, en Sécurité et en Environnement ;</dt>
                    <dt>- Participer à l'évaluation des risques, et à la construction du système de management de la santé et de la sécurité au travail ;</dt>
                    <dt>- Participer à la validation de méthodes de production ou d'analyse en recherchant la meilleure adéquation entre les normes en vigueur et les réalités du terrain;</dt>
                    <dt>- Comprendre les contraintes socio-économiques, les exigences du marché et les stratégies de l'entreprise tout en se situant dans une relation « client-fournisseur ».</dt>
                </dd>

                 La mise en œuvre de ces différents aspects conduira l'assistant technique à animer des groupes de travail pour rechercher les améliorations adaptées aux problématiques rencontrées. Il s'impliquera également dans la mise en place d'actions de formation dans les domaines de la Qualité, la Sécurité et l'Environnement.
                </p>
            </div> */}
            <li id='pv'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Public visé </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Cette formation concerne :
                <dd className='indent-3 space-y-2 mb-2'>
                    <dt>- les salariés en activités ;</dt>
                    <dt>- les demandeurs d'emploi ;</dt>
                    <dt>- les étudiants</dt>
                </dd>
                </p>
            </div>
            <li id='ddc'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Déroulement des cours</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Les cours se déroulent en mode hybride c’est-à-dire une grande partie des enseignements en ligne (environ 80%) via la plateforme TEAMS et le reste en présentiel (environ 20%). Les experts formateurs sont des enseignants-chercheurs issus de l’Université NANGUI ABROGOUA et des Professionnels vacataires issus de diverses entreprises.
                <dd className='indent-3 space-y-2 mb-2'>
                    <dt className='text-black font-medium underline '> Heures de cours </dt>
                    <dt className='flex items-center indent-1 ml-4'> <FcClock size={25} /> 12 h 30 - 14 h 30</dt>
                    <dt className='flex items-center indent-1 ml-4'> <FcClock size={25} /> 18 h 30 - 20 h 30</dt>
                </dd>
                
                <p>
                 <span className='underline text-black'>NB:</span> Ces plages horaires peuvent être modifiée à la demande des enseignants ou des auditeurs.
                </p>
                </p>
            </div>
            <li id='exa'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Les examens </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Après la fin de l’ensemble des cours, un examen final est prévu en présentiel à l’Université NANGUI ABROGOUA. Une période de révision allant de 2 à 3 semaines est accordée aux auditeurs. Les examens au CFC – UNA ont lieu les samedi et dimanche ou en semaine selon la disponibilité des apprenants. Les examens de la première session se déroulement d’abord, ensuite viennent ceux de la deuxième session après proclamation des résultats. 
                <dd className='indent-3 space-y-2 mb-2'> 
               <dt className='ml-2'> Est déclaré <span className='text-black font-medium'> ADMIS</span>, tout auditeur ayant obtenu une moyenne supérieure ou égale à <span className='text-black font-medium'>10 sur 20</span> dans toutes les Unités d’Enseignement (UE).</dt>
               <dt className='ml-2'> Est déclaré <span className='text-black font-medium'>AJOURNÉ</span> ou <span className='text-black font-medium'>RECALÉ</span>, tout auditeur ayant obtenu une moyenne inférieure à <span className='text-black font-medium'>10 sur 20</span> dans toutes les UE ou dans certaines UE. </dt> 
              </dd>
            
                </p>
            </div>
            <li id='dip'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Diplôme </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                La formation est sanctionnée par un diplôme signé par <span className='text-black font-medium'>le Président de l’Université NANGUI ABROGOUA.</span>
                </p>
                </div>
        </ul>




    </div>
  )
}
