import React from 'react'
import './Equipe.css'
export default function Equipe() {
  return (
    <div id='equipe' className='flex items-center justify-center flex-col w-full space-y-4' style={{marginTop:'20px'}}>
      <p id='titreEqui' className='font-sans font-semibold text-[35px] text-black'>Notre <span className='text-[#9A3BE5]'>équipe</span>, c’est ?</p>
      <p id='paraP' className='text-center font-sans text-gray-400 tracking-wide font-light px-2'>
      Une team d'Enseignant-Chercheurs(euses)  jeunes et dynamique ainsi que des Professionnels(les) exerçant dans les grandes entreprises nationales et internationnales.Une nouvelle génération d’experts, qui cassent les codes de la formation continue, en proposant les meilleures formations courtes ou longues adaptées au réalité socio-économique de nos états.
      </p>
      <div id='boxEquipes' className='flex flex-row '>
        <div id='item1Equipe' className='w-[400px] rounded-3xl h-[400px]  border border-gray-100 shadow-md flex flex-col items-center justify-center  m-2'>
            <img src={require('../assets/coursenligne.jpeg')} alt=''  className='w-[250px] ' />
            <p id='pIdem' className='text-red-500 text-[80px] font-bold -tracking-normal text-center whitespace-pre'>80%</p>
            <p className='text-[25px] font-normal text-gray-400 '>des cours sont  <span className='text-green-500'> en ligne</span></p>
        </div>
        <div id='item2Equipe' className='w-[400px] rounded-3xl h-[400px]  border border-gray-100 shadow-md flex flex-col items-center justify-center  m-2 '>
            <img src={require('../assets/rdd.jpeg')} alt=''  className='w-[250px] ' />
            <p id='pIdem' className='text-red-500 text-[80px] font-bold tracking-wider text-center whitespace-pre'>300</p>
            <p id='p2Idem' className='text-[25px] font-normal text-gray-400 '>etudiants <span className='text-green-500'> formés</span> chaque année  </p>
        </div>
        <div id='item3Equipe' className='w-[400px] rounded-3xl h-[400px]  border border-gray-100 shadow-md flex flex-col items-center justify-center m-2'>
            <img src={require('../assets/cames.avif')} alt=''  className='w-[200px] rounded-full  ' />
            <p id='pIdem' className='text-red-500 text-[80px] font-bold tracking-wider text-center whitespace-pre'>+3</p>
            <p className='text-[25px] font-normal text-gray-400 px-2 text-center'>diplômes universitaires <span className='text-red-500 bg-red-200 p-1 font-bold'> certifiés </span> </p>
        </div>
      </div>
    </div>
  )
}
