import React, { useState } from 'react'
import {FiArrowRight} from 'react-icons/fi'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { FiX } from "react-icons/fi";
import './header.css'
import { GrMenu } from "react-icons/gr"
export default function Header({visibiliteBackdrop}) {
  const location = useLocation()
  const [close,setClose]=useState(false)
  const openMenu=()=>{
    visibiliteBackdrop(!close)
    setClose(!close)
  }
  const closeMenu=()=>{
    visibiliteBackdrop(!close)
    setClose(!close)
  }
  return (
    <div className=''>
       <div className='headerPortable'>
       {close===false?
       <div className='flex flex-row w-full justify-between px-3 pt-2'> 
       <div className='flex flex-row'>
        <img src={require('../assets/logo_una.png')} style={{width:60,height:60}} alt=''/>
        <p className='w-[100px]  ml-1 font-sans text-blue-500 font-bold tracking-wider' style={{fontSize:'14px'}}>
         CENTRE DE FORMATION CONTINUE
        </p>
       </div>
       <button className='text-white text-[15px]' onClick={()=>openMenu()}> <GrMenu size='25' color='black'/> </button> 
       </div>
      :<div className='absolute top-0 left-0 w-full flex flex-col' style={{background:'#44403c'}}> 
       <div className='flex flex-row w-full justify-between px-3 pt-2'> 
       <div className='flex flex-row'>
        <img src={require('../assets/logo_una.png')} style={{width:60 ,height:60} } alt=''/>
        <p className='w-[100px]  ml-1 font-sans text-blue-500 font-bold tracking-wider' style={{fontSize:'14px'}}>
         CENTRE DE FORMATION CONTINUE
        </p>
       </div>
       <button className='text-white text-[15px ]' onClick={()=>closeMenu()}> <FiX size='25'/> </button> 
       </div>
        <ul className='flex flex-col w-full justify-center '>
        <li className={`text-base ${location.pathname.startsWith('/')?'text-black':" "} cursor-pointer px-6 py-2 font-medium tracking-wide text-gray-300   border-b-slate-200 border-b-2 hover:text-black`}>
          <Link to='/'> Home </Link></li>
        <li className={`text-base ${location.pathname.startsWith('/QSE')?'text-green-500':" "} cursor-pointer px-6 py-2 font-medium tracking-wide text-gray-300  border-b-slate-200 border-b-2 hover:text-green-500`}>
        <Link to='/QSE'>QSE</Link></li>
        <li className={`text-base ${location.pathname.startsWith('/SID')?'text-violet-500':" "} cursor-pointer px-6 py-2 font-medium tracking-wide text-gray-300  border-b-slate-200 border-b-2 hover:text-violet-500`}><Link to='/SID'>SID</Link></li>
        <li className={`text-base ${location.pathname.startsWith('/VDAF')?'text-yellow-800':" "} cursor-pointer px-6 py-2 font-medium tracking-wide text-gray-300  hover:text-yellow-800  border-b-slate-200 border-b-2`}><Link to='/VDAF'>VDAF</Link></li>
        <li className={`text-base ${location.pathname.startsWith('/GRAH')?'text-red-500':" "} cursor-pointer py-2 px-6 font-medium tracking-wide text-gray-300  hover:text-red-500 border-b-slate-200 border-b-2`}><Link to='/GRAH'>GRAH</Link></li>
      </ul>
      <div >
     <Link to='/preinscription' className='w-[150px] my-3 flex text-center font-medium tracking-wide items-center bg-blue-600 m-1 p-2 text-base text-white rounded-xl'>
     Pre-inscription
            <FiArrowRight  width='25'/>
        </Link>
     </div>
    </div>}
       </div>
    <div className='headerPc'>
    <div className='flex flex-row' style={{marginRight:'15rem'}}> 
        <img src={require('../assets/logo_una.png')} style={{width:70 ,height:70} } alt=''/>
        <p className='w-[100px] whitespace-normal text-[16px] ml-1 font-sans text-blue-500 font-bold tracking-wider'>
         CENTRE DE FORMATION CONTINUE
        </p>
    </div>
     <div> 
        <ul className='flex'>
        <li className={`text-xl ${location.pathname.startsWith("")&&'text-black'} cursor-pointer my-1 text-[20px]  px-10 font-medium tracking-wide text-gray-400  border-r-slate-200 border-r-2 hover:text-black`}>
          <Link to='/'> Home </Link></li>
        <li className={`text-xl ${location.pathname.startsWith('/QSE')?'text-green-500':" "} cursor-pointer my-1 text-[20px]  px-10 font-medium tracking-wide text-gray-400 border-r-slate-200 border-r-2 hover:text-green-500`}>
        <Link to='/QSE'>QSE</Link></li>
        <li className={`text-xl ${location.pathname.startsWith('/SID')?'text-violet-500':" "} cursor-pointer my-1 text-[20px]  px-10 font-medium tracking-wide text-gray-400 border-r-slate-200 border-r-2 hover:text-violet-500`}><Link to='/SID'>SID</Link></li>
        <li className={`text-xl ${location.pathname.startsWith('/VDAF')?'text-yellow-800':" "} cursor-pointer my-1 text-[20px]  px-10 font-medium tracking-wide text-gray-400 border-r-slate-200 border-r-2 hover:text-yellow-800`}><Link to='/VDAF'>VDAF</Link></li>
        <li className={`text-xl ${location.pathname.startsWith('/GRAH')?'text-red-500':" "} cursor-pointer my-1 text-[20px]  px-10 font-medium tracking-wide text-gray-400  hover:text-red-500`}><Link to='/GRAH'>GRAH</Link></li>
      </ul>
    </div>
     <div style={{marginLeft:'5rem'}}>
     <Link to='/preinscription' className='flex text-center font-medium tracking-wide items-center bg-blue-600 m-1 p-2 text-base text-white rounded-xl'>
            Pre-inscription
            <FiArrowRight  width='25'/>
        </Link>
     </div>
   
    </div>
    </div>
  )
}
