import React from 'react'
import { FcClock } from 'react-icons/fc'

export default function INFORMATION_VDAF() {
  return (
    <div>
        <ul className=''>
            <li id='cj'  className='font-medium text-2xl tracking-wider py-3 px-1'>Context et justification</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                L’originalité de ce master repose sur la prise en compte des différents aspects des problèmes liés à la reproduction des déchets générés par l’agro-industrie et l’industrie forestières, les impacts desdits déchets sur l’environnement et la santé humaine, et surtout la possibilité de valoriser ceux-ci en produits à valeurs ajoutées. En effet, l’économie de la Côte d’Ivoire, à l’instar de celle de nombreux pays subsahariens, est basée sur l’agriculture. Le développement de l’exploitation agricole ou forestière génère de nombreux déchets. Ces déchets constituent une préoccupation majeure aussi bien pour les petites et moyennes entreprises (PME) que pour les grands groupes industriels. En effet, ceux-ci sont tenus au terme de diverses normes, d’assurer la valorisation des déchets, s’ils veulent obtenir des financements sur le plan international ou trouver des marchés à leurs productions. Par ailleurs, la valorisation des déchets n’a presque pas été prise en compte dans les politiques de formation. Pourtant, cette valorisation pourrait permettre d’améliorer les conditions de vie des exploitants à travers la vente des nouveaux produits.
                En somme, il est donc imperatif d'accorder de l'intérêt à cette formation qui permettre de former des ressources humaines compétentes en vue encadrer les productions des déchets agroindustriels et de foresteries, transformer ceux-ci en produits nouveaux à valeur ajoutée, et conseiller les politiques d’exploitation soutenable des ressources naturelles.
                </p>
            </div>
            <li id='bf'  className='font-medium text-2xl tracking-wider py-3 px-1'>Besoins de formation</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                La formation met l’accent sur les aspects de la production des déchets agroindustriels, leurs valorisations industrielles, et les impacts desdits déchets sur l’environnement et la santé humaine. Le master MVDAF vise à former des cadres capables de conseiller les meilleures pratiques de production et de valorisation des déchets agroindustriels, et d’orienter les politiques de production agroindustrielles soutenables.
                </p>
            </div>
            <li id='cac'  className='font-medium text-2xl tracking-wider py-3 px-1'>Competence à acquierir</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Le titulaire du Master MVDAF est capable de :
                <dd>
                    <dt>
                    •	organiser tous les systèmes de collectes, valoriser et traiter des déchets agroindustriels et de foresteries ;
                    </dt>
                    <dt>
                    •	conseiller les procédés de transformations des déchets agroindustriels et de foresteries ;
                    </dt>
                    <dt>
                    •	analyser les problématiques liées aux pollutions ;
                    </dt>
                    <dt>
                    •	connaître et comprendre les stratégies de valorisation industrielle des déchets agroindustriels et de foresteries ;
                    </dt>
                    <dt>
                    •	réaliser un montage de projets de valorisation des déchets agroindustriels et de foresteries ;
                    </dt>
                    <dt>
                    •	conseiller les choix méthodologiques conduisant à l’extraction, la purification et l’analyse des compositions d’un produit isolé ;
                    </dt>
                    <dt>
                    •	savoir mener un transfert de technologie de laboratoire vers la production ;
                    </dt>
                    <dt>
                    •	savoir créer une entreprise, sur base de la transformation des déchets agroindustriels et de foresteries.
                    </dt>
                   
                </dd>
                </p>
            </div>
            
            <li id='pv'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Public visé </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Cette formation est ouverte à toute personne issue d’une formation initiale ou relevant d’une formation continue (étudiant, professionnel ou non, salariés du secteur publique ou privé, demandeur d’emploi, etc.) ayant le niveau requis et désireuse d’acquérir un savoir-faire en gestion durable des ressources naturelles.
                </p>
            </div>
            <li id='pv'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Partenaires potentiels </li>
            <div className='mb-5'>
            <p className='text-lg text-gray-600 font-normal'>
                <dd>
                    <dt>•	FIRCA</dt>
                    <dt>•	MINADER</dt>
                        <dt>•	UVICOCI</dt>
                        <dt>•	ADERCI</dt>
                        <dt>•	IPCI</dt>
                        <dt>•	ANADER</dt>
                        <dt>•	CNRA</dt>
                        <dt>•	AGRIVAR</dt>
                        <dt>•	FDFP</dt>

                </dd>
                </p>
            </div>
            <li id='ddc'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Déroulement des cours</li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Les cours se déroulent en mode hybride c’est-à-dire une grande partie des enseignements en ligne (environ 80%) via la plateforme TEAMS et le reste en présentiel (environ 20%). Les experts formateurs sont des enseignants-chercheurs issus de l’Université NANGUI ABROGOUA et des Professionnels vacataires issus de diverses entreprises.
                <dd className='indent-3 space-y-2 mb-2'>
                    <dt className='text-black font-medium underline '> Heures de cours </dt>
                    <dt className='flex items-center indent-1 ml-4'> <FcClock size={25} /> 12 h 30 - 14 h 30</dt>
                    <dt className='flex items-center indent-1 ml-4'> <FcClock size={25} /> 18 h 30 - 20 h 30</dt>
                </dd>
                
                <p>
                 <span className='underline text-black'>NB:</span> Ces plages horaires peuvent être modifiée à la demande des enseignants ou des auditeurs.
                </p>
                </p>
            </div>
            <li id='exa'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Les examens </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                Après la fin de l’ensemble des cours, un examen final est prévu en présentiel à l’Université NANGUI ABROGOUA. Une période de révision allant de 2 à 3 semaines est accordée aux auditeurs. Les examens au CFC – UNA ont lieu les samedi et dimanche ou en semaine selon la disponibilité des apprenants. Les examens de la première session se déroulement d’abord, ensuite viennent ceux de la deuxième session après proclamation des résultats. 
                <dd className='indent-3 space-y-2 mb-2'> 
               <dt className='ml-2'> Est déclaré <span className='text-black font-medium'> ADMIS</span>, tout auditeur ayant obtenu une moyenne supérieure ou égale à <span className='text-black font-medium'>10 sur 20</span> dans toutes les Unités d’Enseignement (UE).</dt>
               <dt className='ml-2'> Est déclaré <span className='text-black font-medium'>AJOURNÉ</span> ou <span className='text-black font-medium'>RECALÉ</span>, tout auditeur ayant obtenu une moyenne inférieure à <span className='text-black font-medium'>10 sur 20</span> dans toutes les UE ou dans certaines UE. </dt> 
              </dd>
            
                </p>
            </div>
            <li id='dip'  className='font-medium text-2xl tracking-wider py-3 px-1 '>Diplôme </li>
            <div className='mb-5'>
                <p className='text-lg text-gray-600 font-normal'>
                La formation est sanctionnée par un diplôme signé par <span className='text-black font-medium'>le Président de l’Université NANGUI ABROGOUA.</span>
                </p>
                </div>
        </ul>
    </div>
  )
}
