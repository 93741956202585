import React, { useEffect, useState } from'react'
import {FcBookmark, FcGraduationCap} from'react-icons/fc'
import { Link, useLocation } from'react-router-dom'
import'./SousMenuSID.css'
import { FaChevronDown, FaChevronUp } from'react-icons/fa'

export default function SousMenuSID(p) {
  const location=useLocation()
  const [item,setItem]=useState(location.pathname)
  const [close,setClose]=useState(false)
  const [closeBtn,setCloseBtn]=useState(false)
  useEffect(()=>{ 
    setClose(p)
  }
  ,[p])
  const onClose=()=>{
    setClose((prev)=>!prev)
    setCloseBtn((prev)=>!prev)
  }
  return (
    <div>
      <button className='libelleQSE text-center' onClick={()=>onClose()}>
       {item==='/SID'?<FcBookmark size={25} />:<FcGraduationCap size={25} />}
       <div style={{color:'#d946ef'}} className='font-bold text-center pl-2 mr-2 flex justify-between w-full'  > 
         <span> {item==='/SID'?'Informations génerales':item==='/SID/l2'?' Licence 2':item==='/SID/l3'?' Licence 3':item==='/SID/m1'?' Master 1':' Master 2'} </span>  
      <div className='pt-1'> {closeBtn==false?<FaChevronDown size={15} color='black'/>:<FaChevronUp color='black' size={15} />} </div>
        </div>
       </button>
   {close===true&&<ul className='memuSID space-y-2'>
     <li style={{color:`${item==='/SID'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2  items-center  ${item==='/SID'?' font-bold ':'text-gray-400'}`}> <FcBookmark size={25} />
        <Link to='/SID' > Informations génerales </Link>
         </li>
     <li style={{color:`${item==='/SID/l2'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2  items-center  ${item==='/SID/l2'?' font-bold ':'text-gray-400'}`}> <FcGraduationCap size={25} />
        <Link to='/SID/l2' > Licence 2 </Link>
         </li>
     <li style={{color:`${item==='/SID/l3'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2  items-center  ${item==='/SID/l3'?' font-bold ':'text-gray-400'}`}> <FcGraduationCap size={25} />
        <Link to='/SID/l3' > Licence 3 </Link>
         </li>
     <li style={{color:`${item==='/SID/m1'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2  items-center  ${item==='/SID/m1'?' font-bold ':'text-gray-400'}`}> <FcGraduationCap size={25} />
        <Link to='/SID/m1' > Master 1 </Link>
         </li>
     <li style={{color:`${item==='/SID/m2'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2  items-center  ${item==='/SID/m2'?' font-bold ':'text-gray-400'}`}> <FcGraduationCap size={25} />
        <Link to='/SID/m2'> Master 2 </Link>
         </li>
   </ul>}
   <ul id='memuSIDPC' className='p-2 space-y-1 text-base rounded-2xl font-medium tracking-wider font-sans text-start bg-gray-100 w-[250px] mx-2 mt-10'>
     <li style={{color:`${item==='/SID'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2   items-center `}> <FcBookmark size={25} />
        <Link to='/SID' > Informations génerales </Link>
         </li>
     <li style={{color:`${item==='/SID/l2'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2   items-center `}> <FcGraduationCap size={25} />
        <Link to='/SID/l2' > Licence 2 </Link>
         </li>
     <li style={{color:`${item==='/SID/l3'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2   items-center `}> <FcGraduationCap size={25} />
        <Link to='/SID/l3' > Licence 3 </Link>
         </li>
     <li style={{color:`${item==='/SID/m1'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2   items-center`}> <FcGraduationCap size={25} />
        <Link to='/SID/m1' > Master 1 </Link>
         </li>
     <li style={{color:`${item==='/SID/m2'?'#d946ef':'#9ca3af'}`}} className={`cursor-pointer flex flex-row space-x-2   items-center`}> <FcGraduationCap size={25} />
        <Link to='/SID/m2'> Master 2 </Link>
         </li>
   </ul>
 </div>
  )
}
