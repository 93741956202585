import React from 'react'
import Typewriter from 'typewriter-effect';
import "./sectionAvant.css"
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import "./sectionAvant.css"
export default function SectionAvant() {
  const longText=(text)=>{
    return text.length +1
  }
  return (
    <div id='avant' className='flex flex-row w-full px-10 justify-between items-center'>
      <div id='avantGauche' className='flex items-center  w-[500px] h-[200px]'>

    <p className='font-sans font-semibold text-[40px] text-black  '>
      Etudiez au  <span className='bg-green-500 text-white p-1 font-bold' > CFC UNA</span> et dévenez un(e)
   <Typewriter   onInit={(typewriter) => {
      typewriter
       .typeString('<strong><span style="color: #27ae60;">Animateur(trice) Qualité</span></strong>').pauseFor(200)
       .deleteChars(longText('Animateur(trice) Qualité'))
       .typeString('<strong><span style="color: #27ae60;">Technicien(ne) de production et de contrôle qualité</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Technicien(ne) de production et de contrôle qualité'))
       .typeString('<strong> <span style="color: #27ae60;">Responsable de laboratoire</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Responsable de laboratoire'))
       .typeString('<strong> <span style="color: #27ae60;">Responsable des services techniques</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Responsable des services techniques'))
       .typeString('<strong> <span style="color: #27ae60;">Responsable sécurité</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Responsable sécurité'))
       .typeString('<strong> <span style="color: #27ae60;">Responsable environnement</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Responsable environnement'))
       .typeString('<strong> <span style="color: #27ae60;">Technicien(ne) en Recherche et Développement</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Technicien(ne) en Recherche et Développement'))
       .typeString('<strong><span style="color: #a038f1;">Consultant architecture Data warehouse</span></strong>')
       .deleteChars(longText('Consultant architecture Data warehouse'))
       .typeString('<strong><span style="color: #a038f1;">Assistant responsable technique</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Assistant responsable technique'))
       .typeString('<strong> <span style="color: #a038f1;">Consultant décisionnel</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Consultant décisionnel'))
       .typeString('<strong> <span style="color: #a038f1;">Data miner</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Data miner'))
       .typeString('<strong> <span style="color: #a038f1;">Responsable des ciblages et extractions de fichiers</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Responsable des ciblages et extractions de fichiers'))
       .typeString('<strong> <span style="color: #a038f1;">Superviseur de l\'activité Web</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Superviseur de l\'activité Web'))
       .typeString('<strong> <span style="color: #a038f1;">Superviseur de l\'activité centre d\'appel</span></strong>')
       .pauseFor(200)
       .deleteChars(longText('Superviseur de l\'activité centre d\'appel'))
       .typeString('<strong> <span style="color: #a038f1;">Administrateur de Bases de données</span></strong>')
        .start()
      
   }}
  
   />
 
   
</p>
    </div>
    <div id='avantdroite' className='flex items-center flex-col'>
       <img src={require('../assets/SID_image.webp')} width={500} height={350}/>
    
         <Link id='prePC' to='/preinscription' className='flex text-center font-medium tracking-wide items-center bg-blue-600 m-1 p-2 text-base text-white rounded-xl'>
         Pre-inscription
            <FiArrowRight  width='25'/>
        </Link></div>
  </div>
  )
}
